import { Image } from '@graphcommerce/image'
import { i18n } from '@lingui/core'
import { Paper } from '@mui/material'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { useState } from 'react'
import arrowLeft from '../../../assets/images/arrow-left.svg'
import arrowRight from '../../../assets/images/arrow-right.svg'
import useWindowSize from '../../../hooks/useWindowSize'
import { RowBrandsBannerFragment } from './RowBrandsBanner.gql'

export type BrandProps = {
  src: string
  url: string
  alt: string
}

type RowBrandsBannerProps = RowBrandsBannerFragment

export function RowBrandsBanner(props: RowBrandsBannerProps) {
  const { brands, sectionLabel } = props
  const { isMobile } = useWindowSize()
  const [isPlaying, setIsPlaying] = useState(true)
  return (
    <div className='flex w-full items-center justify-center'>
      <Paper
        elevation={3}
        className='mx-[10px] mb-[20px] w-full max-w-maxContentWidth rounded-md bg-gradient-to-b from-new-templi-purple/20 md:mx-[20px] md:mb-5 md:px-[60px] '
      >
        <div className='relative flex items-center pb-5 pt-10 lg:mx-0'>
          <span className='Type-XXL-Regular lg:Type-H4-Regular md:Type-XXXL-Regular mx-4 text-black sm:italic'>
            {sectionLabel}
          </span>
        </div>
        <div className='mb-5 w-full lg:mb-10'>
          <CarouselProvider
            naturalSlideWidth={400}
            naturalSlideHeight={100}
            totalSlides={brands.length}
            interval={5000}
            isPlaying={isPlaying}
            step={2}
            visibleSlides={isMobile ? 4 : 6}
            infinite
            isIntrinsicHeight
            className='mx-5 grid grid-cols-[5fr_90fr_5fr] self-center'
          >
            <ButtonBack className='flex items-center justify-center justify-self-start hover:scale-110'>
              <Image src={arrowLeft} alt='Back' layout='fixed' unoptimized height={20} />
            </ButtonBack>
            <Slider
              onMouseEnter={() => setIsPlaying(false)}
              onMouseLeave={() => setIsPlaying(true)}
            >
              {brands?.map((brand, index) => (
                <Slide
                  key={brand.id}
                  id={`slide${index}${brand.id}`}
                  index={index}
                  className='mx-2 flex items-center justify-center justify-self-start md:mx-8 lg:mx-5'
                  innerClassName='flex items-center justify-center hover:scale-110'
                >
                  <a href={brand.url || '#'} target='_blank' rel='noreferrer'>
                    <Image
                      src={brand.image?.url || '/'}
                      alt={brand.alt || ''}
                      layout='fixed'
                      height={100}
                    />
                  </a>
                </Slide>
              ))}
            </Slider>
            <ButtonNext className='flex items-center justify-center justify-self-end hover:scale-110'>
              <Image src={arrowRight} alt='Back' layout='fixed' unoptimized height={20} />
            </ButtonNext>
          </CarouselProvider>
        </div>
      </Paper>
    </div>
  )
}
