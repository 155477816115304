import { Image } from '@graphcommerce/image'
import { Tooltip } from '@mui/material'
import icon1 from '../../assets/images/home-artwork-icon.svg'
import icon3 from '../../assets/images/home-protect-icon.svg'
import icon2 from '../../assets/images/home-reorder-banner-icon.svg'
import icon4 from '../../assets/images/home-smiley-icon.svg'
import { IconDescriptionGrid } from './WhyUseTempli'

export function HeroBannerIcons() {
  const data: IconDescriptionGrid[] = [
    {
      icon: icon1,
      details: 'Unlimited artwork help',
    },
    {
      icon: icon2,
      details: 'Verified suppliers',
    },
    {
      icon: icon3,
      details: 'Put Reorders on Autopilot',
    },
    {
      icon: icon4,
      details: '100% Satisfaction Guaranteed',
    },
  ]
  return (
    <div className='hidden w-full flex-col items-center justify-center md:flex'>
      <div className='bg-trans mx-[10px] mb-5 w-full max-w-maxContentWidth rounded-md md:mx-[20px] md:mb-5 md:px-[60px]'>
        <div className=' grid grid-cols-4 items-center justify-center justify-items-center gap-3'>
          {data.map((item) => (
            <Tooltip title={item.details} arrow>
              <div className='flex items-center justify-center'>
                <Image
                  unoptimized
                  src={item?.icon}
                  height={25}
                  width={25}
                  layout='fixed'
                  className='flex h-[25px] w-[25px] items-center justify-center'
                />
                <span className='ml-3 hidden text-center md:block'>{item.details}</span>
              </div>
            </Tooltip>
          ))}{' '}
        </div>
      </div>
    </div>
  )
}
