import PageLink from 'next/link'
import { Button } from '../../Button'
import { SecondaryHeaderButton, StandardPrimaryButton } from '../../Button/ButtonStyles'
import { HeroBanner } from './HeroBanner'
import { RowHeroBannerFragment } from './RowHeroBanner.gql'

export function RowHeroBanner(props: RowHeroBannerFragment) {
  const { banners, headerText, pageLinks, headerTitle } = props
  return (
    <HeroBanner
      pageLinks={pageLinks.map((pageLink, index) => (
        <PageLink key={pageLink.url} href={pageLink.url} passHref>
          {index === 0 && (
            <StandardPrimaryButton
              type='button'
              className='w-auto bg-new-templi-purple uppercase'
              variant='contained'
              size='small'
            >
              {pageLink.title}
            </StandardPrimaryButton>
          )}
          {index > 0 && (
            <SecondaryHeaderButton
              type='button'
              className='w-auto bg-secondary-templi-yellow uppercase'
              variant='contained'
              size='small'
            >
              {pageLink.title}
            </SecondaryHeaderButton>
          )}
        </PageLink>
      ))}
      headerText={headerText}
      headerTitle={headerTitle}
      banners={banners}
    />
  )
}
