import { RichText } from '@graphcommerce/graphcms-ui'
import { Paper } from '@mui/material'
import { SectionTitle } from '../../Container/SectionTitle'
import { RowServicePageContentFragment } from './RowServicePageContent.gql'

type RowServicePageContentProps = RowServicePageContentFragment

export function RowServicePageContent(props: RowServicePageContentProps) {
  const { pageTitle, content } = props

  return (
    <div className='mx-[40px] my-[60px] shadow-xl md:mx-[15%] lg:mx-[22%]'>
      <Paper elevation={3} className='md:px-15 p-10 md:py-8'>
        <SectionTitle withHorizontalDivider title={pageTitle ?? ''} className='w-[60%]' />
        <RichText raw={content?.raw} />
      </Paper>
    </div>
  )
}
