import { Image } from '@graphcommerce/image'
import { ContainerProps, Paper } from '@mui/material'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import React from 'react'

export type ImageProps = {
  id: string
  image: {
    url: string
    alt?: string | null | undefined
  }
  isMobile?: boolean | null | undefined
}

export type HeroBannerProps = ContainerProps & {
  banners: ImageProps[]
  pageLinks: React.ReactNode
  headerTitle?: string | null
  headerText?: string | null
}

export function HeroBanner(props: HeroBannerProps) {
  const { banners, pageLinks, headerText, headerTitle, ...containerProps } = props
  const desktopBanner = banners && banners?.filter((item) => !item.isMobile)
  const mobileBanner = banners && banners?.filter((item) => item.isMobile)

  return (
    <div {...containerProps} className='mb-5 flex w-full items-center justify-center'>
      <div className='justify-cente flex w-full max-w-maxContentWidth items-center '>
        <div className='relative block min-w-full bg-templi-purple'>
          {desktopBanner && desktopBanner?.length > 0 && (
            <div className='hidden md:block'>
              <Image
                src={desktopBanner[0].image.url}
                alt={desktopBanner[0].image.alt || ''}
                height={400}
                layout='intrinsic'
                className='object-contain'
                loading='eager'
              />
            </div>
          )}
          {mobileBanner && mobileBanner?.length > 0 && (
            <div className='block md:hidden'>
              <Image
                src={mobileBanner[0].image.url}
                alt={mobileBanner[0].image.alt || ''}
                height={400}
                layout='intrinsic'
                className='object-contain'
                loading='eager'
              />
            </div>
          )}
          <Paper
            className='absolute bottom-0 left-0 mb-5 ml-5 flex h-auto w-2/3 flex-col justify-between rounded-md p-5 lg:mb-10 lg:ml-10 lg:h-[60%] lg:w-5/12 lg:p-8'
            elevation={6}
            sx={{
              backgroundColor: '#F3F3F3',
            }}
          >
            <h1 className='mb-6 flex h-1/2 flex-col items-start justify-center gap-3'>
              <span className='lg:Type-XXL-Bold Type-XL-Bold mb-1'>{headerTitle}</span>
              <span className='lg:Type-XL-Regular Type-Large-Regular'>{headerText}</span>
            </h1>
            <div className='flex gap-8'>{pageLinks}</div>
          </Paper>
          {/* <div className='absolute left-0 top-0 h-full w-1/2 bg-transparent lg:ml-10 lg:w-1/2' /> */}
        </div>
      </div>
    </div>
  )
}
