import { CustomerDocument, useCustomerQuery } from '@graphcommerce/magento-customer'
import { i18n } from '@lingui/core'
import { Paper } from '@mui/material'
import { ReviewItem } from '../../Reviews/ReviewItem'
import { RowReviewHighlightFragment } from './RowReviewHighlight.gql'
import { reviewHighlightsData } from './data'

type RowReviewHighlightProps = RowReviewHighlightFragment

export function RowReviewHighlight(props: RowReviewHighlightProps) {
  const { id, reviewItems } = props
  const dashboard = useCustomerQuery(CustomerDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const customer = dashboard.data?.customer
  return (
    <div className='flex w-full flex-col items-center justify-center'>
      <Paper
        elevation={3}
        className='mx-[10px] mb-5 w-full max-w-maxContentWidth rounded-md bg-gradient-to-b from-new-templi-purple/20 md:mx-[20px] md:mb-5 md:px-[60px] '
      >
        <div className='relative my-5 flex items-center lg:mx-0'>
          <span className='Type-XXL-Regular lg:Type-H4-Regular md:Type-XXXL-Regular mx-4 text-black sm:italic'>
            {i18n._('What customers are saying')}
          </span>
        </div>
        <div className='grid grid-cols-1 gap-[20px] p-[20px] pt-0 md:grid-cols-2 md:flex-row'>
          {reviewItems?.map((review) => (
            <div className='rounded-[20px]p-[20px] w-full md:w-[calc(33vw-20px)]'>
              <ReviewItem
                id={0}
                created_at={review.dateCreated}
                verified_buyer
                images_data={[]}
                {...review}
                customer={customer}
                displayVotes={false}
                displayImages={false}
                avatar_url={review.avatar?.url}
                displayDate={false}
                displayReplies={false}
                isHomePageItem
              />
            </div>
          ))}
        </div>
      </Paper>
    </div>
  )
}
