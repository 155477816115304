import { Paper } from '@mui/material'
import { RowParagraphFragment } from './RowParagraph.gql'
// import { RowParagraphFragment } from './RowParagraph.gql'

type RowParagraphProps = RowParagraphFragment

export function RowParagraph(props: RowParagraphProps) {
  const { paragraph, paragraphTitle } = props

  return (
    <div className='mb-[20px] flex w-full items-center justify-center'>
      <Paper
        elevation={3}
        className='md:bg-white mx-[10px] flex w-full max-w-maxContentWidth flex-col rounded-md px-[20px] md:mx-[20px] md:px-[60px]'
      >
        <span className='Type-XXXL-Medium my-10 italic'>{paragraphTitle}</span>
        <span className='Type-XXL-Regular mb-10'>{paragraph}</span>
      </Paper>
    </div>
  )
}
