import { Paper } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { StandardButton } from '../../Button/ButtonStyles'
import { ResponsiveImageGridItem } from '../../Image/ResponsiveImageGridItem'
import { RowFeaturedCategoryFragment } from './RowFeaturedCategory.gql'

type RowFeaturedCategoryProps = RowFeaturedCategoryFragment

export function RowFeaturedCategory(props: RowFeaturedCategoryProps) {
  const { categoryItems, sectionLabel } = props
  const router = useRouter()
  const handleClick = async (href: string) => {
    await router.push(href)
  }

  return (
    <div className='flex w-full items-center justify-center'>
      <Paper
        elevation={3}
        className='md:bg-white mx-[10px] mb-5 w-full max-w-maxContentWidth rounded-md px-[20px] md:mx-[20px] md:px-[60px]'
      >
        <div className='relative flex items-center py-5 lg:mx-0'>
          <span className='Type-XXL-Regular lg:Type-H4-Regular md:Type-XXXL-Regular mx-4 text-black sm:italic'>
            {sectionLabel}
          </span>
        </div>
        <div className='flex flex-col items-center justify-items-center gap-[20px] rounded-[12px] pb-5 lg:grid lg:grid-cols-5 lg:gap-10 lg:pb-7'>
          {categoryItems?.map((category) => (
            <Link
              href={category.categoryImage?.url ?? ''}
              key={category.categoryLabel}
              className='w-full  text-black hover:text-material-ui-blue'
            >
              <ResponsiveImageGridItem
                src={category.categoryImage?.image?.url ?? ''}
                alt={category.categoryImage?.image?.alt ?? ''}
                aspect='4/3'
              />

              <span className='md:Type-XXL-Regular Type-XXL-Regular mt-2 text-center md:my-3'>
                {category.categoryLabel}
              </span>
            </Link>
          ))}
        </div>
        <div className='mb-6 flex w-full items-center justify-center'>
          <StandardButton
            variant='contained'
            size='small'
            className='bg-material-ui-blue'
            onClick={() => handleClick('/search')}
          >
            VIEW ALL
          </StandardButton>
        </div>
      </Paper>
    </div>
  )
}
