import { Image } from '@graphcommerce/image'
import { RowProductHighlightFragment } from './RowProductHighlight.gql'

type RowProductHighlightProps = RowProductHighlightFragment

export function RowProductHighlight(props: RowProductHighlightProps) {
  const { mainImage } = props
  return (
    <div className='mb-[20px] flex w-full items-center justify-center'>
      <div className='mx-[10px] flex w-full max-w-maxContentWidth items-center justify-center md:m-[20px] md:mt-0'>
        <Image src={mainImage?.url ?? ''} layout='fixed' width={1536} height={300} />
      </div>
    </div>
  )
}
